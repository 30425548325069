.blur{
    filter: blur(4px);
}
.qrinput input {
    border: 1px solid #dce3f3;
  }

  .yellowbtn[disabled] {
    cursor: not-allowed !important;
    pointer-events: initial;
  }
  .small-input input[type = "password"]:focus,.small-input input[type = "password"],
  .small-input input[type = "password"]:focus-visible,
  .small-input input[type = "password"]:focus-within,
  .small-input input[type = "password"]:active,
  .small-input input[type = "password"]:target
   {
    background: #dce3f3;
  }

  .transbtn.profimg img {
    border-radius: 50%;
  }
.transicon {
    background: transparent;
    border: none;
    color: var(--themeclr);
  }